* {
  box-sizing: border-box;
}

p {
  font-weight: 400 !important;
}

.navbar-translate {
  width: 40%;
}

@media (max-width: 991.98px) {
  .navbar-translate {
    width: 100%;
  }
}

.form-control {
  font-weight: 500 !important;
  color: #000;
}

.form-control::placeholder {
  opacity: 0.3 !important;
}
